<template>
  <div class="wrapper">
    <div class="content">
      <div class="step">
        <van-steps :active="active">
          <van-step>未处理</van-step>
          <van-step>处理中</van-step>
          <van-step>已处理</van-step>
        </van-steps>
      </div>
      <div class="detail-info">
        <div class="local_left">
          <span>地理位置:</span>
          <van-field
            v-model="detailInfo.address"
            placeholder="请输入"
            label-width="60px"
            rows="2"
            type="textarea"
          />
        </div>
      </div>
      <div class="course">
        <span>报事原因:</span>
        <div class="input">
          <van-field
            rows="3"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入"
            show-word-limit
            v-model="detailInfo.content"
          />
        </div>
      </div>
      <div class="upload">
        <span>上传图片</span>
        <div class="image-content">
          <div
            class="images"
            v-for="(item, index) in detailInfo.img"
            :key="index"
          >
            <van-image class="img" :src="item">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
export default {
  created() {
    if (this.$route.query) {
      this.detailInfo = this.$route.query.item;
      console.log(this.detailInfo);
    }
  },
  computed: {
    active() {
      if (this.detailInfo.type == 2) return 0;
      if (this.detailInfo.type == 1) return 1;
      if (this.detailInfo.type == 3) return 2;
    },
  },
  data() {
    return {
      detailInfo: "",
    };
  },
  components: {
    MyHeader,
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  .step {
    margin-bottom: 20px;
  }
  .local {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid gainsboro;
    font-weight: bold;
    margin-top: 30px;
    font-size: 15px;
    .local_left {
      width: 100%;
      ::v-deep .van-cell {
        padding: 5px;
      }
    }
  }
  .course {
    margin-top: 20px;
    span {
      font-weight: bold;
      font-size: 14px;
    }
    .input {
      border-bottom: 1px solid gainsboro;
    }
  }
  .upload {
    flex: 1;
    display: flex;
    flex-direction: column;
    .image-content {
      margin-top: 30px;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      .images {
        overflow: hidden;
        width: 80px;
        height: 80px;
        margin-right: 16px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

span {
  color: #646566;
  font-size: 14px;
  font-weight: bold;
}
::v-deep .van-cell {
  padding: 10px;
  border-bottom: 1px solid #ebedf0;
  font-size: 15px;
  color: #0000 !important;
}
::v-deep .van-steps--horizontal {
  padding: 0;
}
</style>
